import React from "react"
// import { Link } from "gatsby"
import MediaAdmin from "../components/media-admin"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from '../config'

Amplify.configure(config)


const AdminPage = () => (
  <Layout menu="ADMIN">
    <SEO title="Admin" />
    <h1>Admin</h1>
    <MediaAdmin />
  </Layout>
)

export default withAuthenticator(AdminPage, {
  includeGreetings: false
 })
