import React, { Component } from "react"
// import Api from '@aws-amplify/api'
// import AppStore from '../services/AppStore'
import BucketService from '../services/BucketService'
import MediaAdminDetail from './media-admin-detail'
import './media-admin.css'

import loadingIcon from "../images/loader.svg"


class MediaAdmin extends Component {
    constructor() {
        super()
        this.state = {
            loading: true,
            bucketList: []
        }
    }
    
    async componentDidMount() {
        let bucketList = await BucketService.getAdminList()

        this.setState({
            loading: false,
            bucketList
        })
    }

    render() {
        if (this.state.loading) return <div className="loader" style={{ margin: '10rem' }}><img src={loadingIcon} alt="loading" /></div>
        
        return <div className='admin-section no-select'>
            {this.state.bucketList.map(bucket => <MediaAdminDetail key={`${bucket.name}_wrap`} data={bucket} />)}
            </div>
    }
}

export default MediaAdmin
