import React, { Component } from "react"
import ApiService from '../services/ApiService'
import AppStore from '../services/AppStore'
import loadingIcon from "../images/loader.svg"

class MediaAdminDetail extends Component { 
    constructor(props) {
        super(props)
        this.state = {
            isLoadingCheck: false,
            isLoadingBuildTree: false,
            s3Count: null,
            dbCount: null,
            imageIssues: null,
            videoIssues: null,
            buildMsg: ''
        }
        this.checkMedia = this.checkMedia.bind(this)
        this.reBuildTree = this.reBuildTree.bind(this)
    }

    async checkMedia () {
        this.setState({
            isLoadingCheck: true
        })

        const checkData = await ApiService.getAdminCheckData(this.props.data.name)

        this.setState({
            isLoadingCheck: false,
            s3Count: checkData.s3Count,
            dbCount: checkData.dbCount,
            imageIssues: checkData.imageIssues,
            videoIssues: checkData.videoIssues,
            rejectedFiles: checkData.rejectedFiles,
            processingSeconds: checkData.processingSeconds
          })
    }

    async reBuildTree () {
        this.setState({
            isLoadingBuildTree: true
        })

        await ApiService.reBuildTree(this.props.data.name)

        this.setState({
            isLoadingBuildTree: false,
            buildMsg: 'Completed!'
        })

        AppStore.clearMediaTree()
    }

    secondsToStr( secondCount ) {
        const years = Math.floor( secondCount / 31536000 ),
              days = Math.floor( ( secondCount %= 31536000 ) / 86400 ),
              hours = Math.floor( ( secondCount %= 86400 ) / 3600 ),
              minutes = Math.floor( ( secondCount %= 3600 ) / 60 ),
              seconds = secondCount % 60;
    
        if ( days || hours || seconds || minutes ) {
          return ( years ? years + "y " : "" ) +
          ( days ? days + "d " : "" ) +
          ( hours ? hours + "h " : ""  ) +
          ( minutes ? minutes + "m " : "" ) +
          Number.parseFloat( seconds ).toFixed( 2 ) + "s";
        }
    
        return "< 1s";
    }

    render () {
        let detailCheck = ''
        let detailRebuild = ''

        if (this.state.isLoadingCheck) {
            detailCheck = <div className="loader"><img src={loadingIcon} alt="loading" /></div>
        }

        if (this.state.buildMsg) {
            detailRebuild = <div className='admin-header'>{this.state.buildMsg}</div>
        }

        if (this.state.isLoadingBuildTree) {
            detailRebuild = <div className="loader"><img src={loadingIcon} alt="loading" /></div>
        }

        if (!this.state.isLoadingCheck && this.state.s3Count) {
            let imageIssueDetail = ''
            let videoIssueDetail = ''
            let ignoredIssueDetail = ''
            if (this.state.imageIssues.length > 0) {
                imageIssueDetail = this.state.imageIssues.map((key, issueIndex) => <div key={`Issue${issueIndex}`}>{key}</div>)
                imageIssueDetail.splice(0, 0, <div className='admin-detail-header'>Images renvoyées pour encodage</div>)
            }
            if (this.state.videoIssues.length > 0) {
                videoIssueDetail = this.state.videoIssues.map((key, issueIndex) => <div key={`Issue${issueIndex}`}>{key}</div>)
                videoIssueDetail.splice(0, 0, <div className='admin-detail-header'>Vidéos renvoyées pour encodage</div>)
            }
            if (this.state.rejectedFiles.length > 0) {
                ignoredIssueDetail = this.state.rejectedFiles.map((key, issueIndex) => <div key={`Issue${issueIndex}`}>{key}</div>)
                ignoredIssueDetail.splice(0, 0, <div className='admin-detail-header'>Fichiers ignorés:</div>)
            }
            detailCheck = <div className='admin-media'>
                <div className='admin-header'>
                    <div>
                            <span className='admin-title'>Nombre de Fichiers:</span><span>{this.state.s3Count}</span>
                        </div>
                        <div>
                            <span className='admin-title'>Entrées base de données:</span><span>{this.state.dbCount}</span>
                        </div>
                        <div>
                            <span className='admin-title'>Nombre d'images manquantes:</span><span>{this.state.imageIssues.length}</span>
                        </div>
                        <div>
                            <span className='admin-title'>Nombre de vidéos manquantes:</span><span>{this.state.videoIssues.length}</span>
                        </div>
                        <div>
                            <span className='admin-title'>Fichiers ignorés:</span><span>{this.state.rejectedFiles.length}</span>
                        </div>
                        <div>
                            <span className='admin-title'>Temps estimé pour finir l'encodage:</span><span>{ this.state.processingSeconds ? this.secondsToStr(this.state.processingSeconds) : '-' }</span>
                        </div>
                    </div>
                    {imageIssueDetail}
                    {videoIssueDetail}
                    {ignoredIssueDetail}
                </div>
        }

        return (
            <div key={`${this.props.data.name}_wrap`} className='admin-bucket'>
                <div>
                    <span>{this.props.data.name}</span>
                </div>

                <fieldset>
                    <legend>Media Processing</legend>
                    <button onClick={this.checkMedia} disabled={this.isLoadingCheck}>Check Media</button>
                    {detailCheck}
                </fieldset>

                <fieldset>
                    <legend>Media Tree Update</legend>
                    <button onClick={this.reBuildTree}>Rebuild Tree</button>
                    {detailRebuild}
                </fieldset>


            </div>)
    }
}

export default MediaAdminDetail
